import request from '@/utils/request'
// 首页列表
export function queryDataList (data) {
  return request({
    url: '/admin/variety-list',
    method: 'post',
    data,
  })
}
// 保存
export function saveDataItem (data) {
  return request({
    url: '/tree-variety/save',
    method: 'post',
    data,
  })
}

// 苗单详情
export function queryVarietyDataDetail (data) {
  return request({
    url: '/tree-variety/info',
    method: 'post',
    data,
  })
}
// 模板信息
export function queryDataDetail (data) {
  return request({
    url: '/photograph/data',
    method: 'post',
    data,
  })
}

//删除苗单绑定的苗木
export function changeDataItem (data) {
  return request({
    url: '/tree-variety/delete-item',
    method: 'post',
    data,
  })
}

// 删除
export function delDataItem (data) {
  return request({
    url: '/tree-variety/delete',
    method: 'post',
    data,
  })
}

