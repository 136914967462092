var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c("nxp-search", {
        attrs: { "config-list": _vm.searchConfigList },
        on: { clickSearch: _vm.clickSearch, resetForm: _vm.resetForm },
      }),
      _c("vab-query-form"),
      _c("select-table", {
        attrs: {
          accessories: _vm.nxpTabelAccessories,
          "only-key-id": "id",
          "show-btn": true,
          "table-columns": _vm.tableColumns,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "w-theight": 320,
        },
        on: {
          delSelect: _vm.delSelect,
          fetchData: _vm.fetchData,
          fetchTableSelection: _vm.fetchTableSelection,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }