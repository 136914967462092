<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <vab-query-form> </vab-query-form>
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :show-btn="true"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @delSelect="delSelect"
      @fetchData="fetchData"
      @fetchTableSelection="fetchTableSelection"
    />
  </div>
</template>

<script>
import { queryDataList, delDataItem } from "@/project/eztree/baseApi/TreeVarietyApi";
const selectOptions = [
  { label: "否", value: "0" },
  { label: "是", value: "1" },
];
const statusOptions = [
  { label: "全部", value: "0" },
  { label: "待号苗", value: "1" },
  { label: "已号苗", value: "2" },
];
export default {
  name: "TreeItem",
  components: {},
  props: {
    project_id: {
      type: String,
    },
    userPhone: {
      type: String,
    },
  },
  data() {
    return {
      selectOptions,
      statusOptions,
      select_table_data: [],
      queryForm: {},
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "phone",
          label: "手机号",
        },
        {
          type: "text",
          showName: false,
          model: "",
          param: "variety_name",
          label: "苗单名称",
        },
        {
          type: "select",
          showName: false,
          model: "",
          param: "status",
          label: "状态",
          options: statusOptions,
        },

        {
          type: "daterange",
          showName: false,
          model: "",
          param: "date",
          label: "日期",
        },
      ],
      userSelect: [],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "components",
        selectType: "checkBox",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "苗店头像",
          prop: "shop_avatar",
          width: "160px",
          align: "center",
          render: function (h, params) {
            return h("div", [
              h("img", {
                attrs: {
                  style: "height:100px;",
                  src: params.row.shop_avatar,
                  alt: "图片走丢了",
                },
              }),
            ]);
          },
        },
        {
          label: "苗店手机号",
          prop: "shop_phone",
          width: "160px",
        },
        {
          label: "苗店名称",
          prop: "shop_name",
          width: "160px",
        },

        {
          label: "项目名称",
          prop: "project_name",
          width: "160px",
        },
        {
          label: "苗单信息",
          key: "info",
          align: "center",
          width: 500,
          render: function (h, params) {
            return h("div", {
              domProps: {
                innerHTML: `<div style="text-align: left; padding: 10px;">
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">苗单名称：${params.row.variety_name}</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">高度：${params.row.height_min}-${params.row.height_max}cm</span>

                          <span style="float:left;width: 50%;">胸地径：${params.row.xdj_min}-${params.row.xdj_max}cm</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">冠幅：${params.row.gf_min}-${params.row.gf_max}cm</span>
                          <span style="float:left;width: 50%;">分支点数：${params.row.fzd_num}</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">分支点高度：${params.row.fzd_height_min}-${params.row.fzd_height_max}cm</span>
                          <span style="float:left;width: 50%;">价格：${params.row.price}元</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">应号苗数量：${params.row.created_num}</span>
                      </div>
                      <div style="line-height: 22px;">
                          <span style="float:left;width: 50%;">备注：${params.row.remark}</span>
                          <span style="float:left;width: 50%;">实号苗数量：${params.row.add_num}</span>
                      </div>
                    </div>`,
              },
            });
          },
        },
        {
          label: "状态",
          prop: "status",
          render: (h, params) => {
            return h(
              "el-tag",
              {
                attrs: {
                  type:
                    params.row.status == "0"
                      ? "info"
                      : params.row.status == "1"
                      ? "warning"
                      : params.row.status == "2"
                      ? "success"
                      : "",
                  effect: "plain",
                },
              },
              params.row.status == "0"
                ? "全部"
                : params.row.status == "1"
                ? "待号苗"
                : params.row.status == "2"
                ? "已号苗"
                : ""
            );
          },
        },
        {
          label: "创建时间",
          width: "180px",
          prop: "created_at",
        },
        {
          label: "修改时间",
          width: "180px",
          prop: "updated_at",
        },
        // {
        //   label: "操作",
        //   width: "180px",
        //   render: (h, params) => {
        //     return h("div", {}, [
        //       h(
        //         "el-button",
        //         {
        //           attrs: {
        //             type: "text",
        //             params: params.row,
        //           },
        //           on: {
        //             ...this.$listeners,
        //             click: () => {
        //               this.$refs["addItemEdit"].showEdit(params.row, "add");
        //             },
        //           },
        //         },
        //         "创建苗木"
        //       ),
        //       h(
        //         "el-button",
        //         {
        //           attrs: {
        //             type: "text",
        //             params: params.row,
        //           },
        //           on: {
        //             ...this.$listeners,
        //             click: () => {
        //               this.$refs["edit"].showEdit(params.row, "edit");
        //             },
        //           },
        //         },
        //         "编辑"
        //       ),

        //       h(
        //         "el-button",
        //         {
        //           attrs: {
        //             type: "text",
        //             params: params.row,
        //           },
        //           on: {
        //             ...this.$listeners,
        //             click: () => {
        //               this.$refs["edit"].showEdit(params.row, "view");
        //             },
        //           },
        //         },
        //         "详情"
        //       ),

        //       h(
        //         "el-button",
        //         {
        //           attrs: {
        //             type: "text",
        //             params: params.row,
        //           },
        //           on: {
        //             ...this.$listeners,
        //             click: () => {
        //               this.handleEdit(params.row, "delete");
        //             },
        //           },
        //         },
        //         "删除"
        //       ),
        //     ]);
        //   },
        // },
      ];
      return columns;
    },
  },
  watch: {},
  created() {},
  methods: {
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      params.project_id = this.project_id;
      if (this.userPhone) {
        this.searchConfigList[0].model = this.userPhone;
        params.phone = this.userPhone;
      }
      const { date } = params;
      if (date) {
        params.start_time = date[0];
        params.end_time = date[1];
        console.log("params", params, date);
        delete params.date;
      }
      queryDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    updateFetch(val) {
      this.queryForm.user_id = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    fetchTableSelection(data) {
      this.select_table_data = data;
    },
    handleEdit(row, action) {
      let variety_ids = [row.id];
      this.$refs["edit"].showEdit(row, action, variety_ids);
    },
    delSelect(row, action) {
      let variety_ids = this.select_table_data.map((ele) => {
        return ele.id;
      });
      delDataItem({ variety_ids })
        .then(({ code, data }) => {
          if (code == "200") {
            this.$baseMessage("删除成功", "success", "vab-hey-message-success");
            this.initQuery();
          }
        })
        .catch(() => {});
    },
  },
};
</script>
